import { useDropzone } from "react-dropzone";
import React, { useState, useCallback, useMemo } from "react";
import XlsUploadIcon from "../../assets/xls.png";
import XlSheet from "../../assets/xlsheets .png";
import CloseIconRounded from "../../assets/close-icon-rounded.png";
import "./style.css";
import { useEffect } from "react";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "rgba(208, 239, 239, 0.27)",
  color: "var(--color-grey-900)",
  fontWeight: "500",
  transition: "border .3s ease-in-out",
  borderRadius: "6px",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "var(--Secondary-green)",
  backgroundColor: "rgba(208, 239, 239, 0.8)",
};

const rejectStyle = {
  borderColor: "rgba(255,85, 85, 0.7)",
  backgroundColor: "rgba(255,0, 13, 0.03)",
};

function FileUploaderDropzone(props) {
  const [files, setFiles] = useState([]);
  const [lengthError, setLengthError] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (files.length < 2) {
        let newFiles = [...files, ...acceptedFiles];
        setFiles(newFiles.flat());
        props.getFiles(newFiles.flat());
        // console.log(newFiles.flat());
      } else {
        setLengthError(true);
      }
    },
    [files, props]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    maxFiles: 2,
    minSize: 0,
    // multiple: true,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  const removeFile = (i) => {
    const newFiles = [...files];
    newFiles.splice(i, 1);
    setFiles(newFiles);
  };

  useEffect(() => {
    if (props.status === true) {
      setFiles([]);
    }
  }, [props.status]);

  return (
    <div className="App">
      <div {...getRootProps({ style })} className="upload_drag_area">
        <input {...getInputProps()} />
        {/* <img src={XlsUploadIcon} alt="" className="icon"  /> */}
        <img src={XlSheet} alt="" className="iconxl" />
        {/* <p>Drag and drop files here or click to select files</p> */}
        <div className="upload_instruction">
          {!isDragActive && "Drag and drop files here or click to select files"}
        </div>
        <div className="upload_instruction">
          {isDragActive && !isDragReject && "Drop the file here"}
        </div>
        <div className="upload_instruction">
          {isDragReject
            ? // ? "File type not accepted! Please upload a .XLSX file."
              "File type not accepted! Please upload a file in .XLS or .XLSX format."
            : lengthError
            ? "Number of files required is 2"
            : ""}
        </div>
      </div>
      {files.length > 0 && files.length <= 2 ? (
        <div className="fileList">
          {files.length > 0 &&
            files.map((file, i) => (
              <div key={i} className="fileList__itemWrapper">
                <div className="fileList__item">
                  {file.name}{" "}
                  <img
                    onClick={(i) => removeFile(i)}
                    src={CloseIconRounded}
                    alt=""
                  />
                </div>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
}

export default FileUploaderDropzone;
